import React, { useEffect } from "react";
import ReactDOM from "react-dom";

import { AppViewModel } from "./view-model/AppViewModel/AppViewModel";
import { App } from "./view/App/App";
import ThemeProvider from "./components/ThemeProvider/ThemeProvider";
import { SnackbarProvider, withSnackbar } from "notistack"
import { createApiLayers } from "./core/Layers/ApiLayers";
import "moment/locale/ru";
import { IngradAuthRepository } from "./repositories/AuthRepository/IngradAuthRepository";

//@ts-ignore
import ServiceWorkerBundleFile from "./workers/service-worker.ts";

(async () => {
  class BundleFile {
    constructor(path: string) {
      this.path = path;
    }
    path: string
  }

  //@ts-ignore
  window.BundleFile = BundleFile;

  const serviceWorkerBundleFile: {
    path: string
  } = new ServiceWorkerBundleFile()

  const authRepository = new IngradAuthRepository()
  const layers = await createApiLayers({
    host: "https://ingrad.sarex.io/",
    pdmHost: "https://api.sarex.io/",
    serviceWorkerPath: serviceWorkerBundleFile.path,
    authRepository,
    components: {}
  })

  //@ts-ignore
  window.layers = layers
  //@ts-ignore
  const history = layers.usecases.route.history

  const SnackbarProviderConnect = withSnackbar((props) => {
    useEffect(() => {
      layers.usecases.notifications.onSend = (message: string, options) => {
        return props.enqueueSnackbar(message, options)
      }

      layers.usecases.notifications.onClose = (key: string | number) => {
        return props.closeSnackbar(key)
      }
    }, [])

    return null
  })


  ReactDOM.render(
    <ThemeProvider>
      <SnackbarProvider maxSnack={3} preventDuplicate>
        <SnackbarProviderConnect />
      </SnackbarProvider>
      <App viewModel={new AppViewModel({ layers })} />
    </ThemeProvider>
    ,
    document.getElementById("root")
  );
})()