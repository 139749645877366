import { action, computed, makeObservable, observable } from "mobx";
import { EUserRoles, TUserProps, User } from "../../models/User";
import { IAuthRepository } from "./interface";
import axios from "axios";
import { RequestStatus } from "../../models/RequestStatus";

type TTokenResponse = {
  access_token: string
  refresh_token: string

  user: {
    id: number
    company: number
    email: string
    first_name: string
    last_name: string
    role: EUserRoles
    username: string
  }
}

export class IngradAuthRepository implements IAuthRepository {
  constructor() {
    makeObservable(this)

    this.loadState()
  }

  status = new RequestStatus({
    loading: true,
    error: undefined
  })

  @observable
  private authorized = false

  @observable
  private user?: User = undefined

  @observable
  private accessToken?: string = undefined

  private loadState() {
    const accessToken: string | null = localStorage.getItem("accessToken")
    const user: TUserProps | null = JSON.parse(localStorage.getItem("user") || "null")

    if (user) {
      this.setUser(new User(user))
    }

    if (accessToken) {
      this.setAccessToken(accessToken)
    }

    if (user && accessToken) {
      this.setAuthorized(true)
    } else {
      this.login()
    }
  }

  @computed
  get urlSearchParams() {
    return new URLSearchParams(window.location.search)
  }

  private getCode() {
    return this.urlSearchParams.get("code")
  }

  private clientId = "c013d423-7618-4293-8255-cbd42dc1a9b9"
  private baseUrl = "https://auth2.ingrad.com/adfs/oauth2"

  // инград не дает возможности указывать иной redirectUrl
  private redirectUrl = "https://ingrad.sarex.io/oauth/complete/"

  // из-за другого redirectUrl приходится сохранять предыдущий location в localStorage
  private localStorageLocationKey = "localStorageLocationKey"

  private redirect() {
    localStorage.setItem(this.localStorageLocationKey, window.location.href);
    window.location.href = `${this.baseUrl}/authorize/?response_type=code&client_id=${this.clientId}&redirect_uri=${this.redirectUrl}`
  }

  getAuthHeaders() {
    return {
      'Authorization': `Bearer ${this.getAccessToken()}`
    }
  }

  async getAuthHeadersAsync() {
    return this.getAuthHeaders()
  }

  getIsAuthorized() {
    return this.authorized
  }

  getUser() {
    return this.user
  }

  getAccessToken() {
    return this.accessToken
  }

  async login() {
    const code = this.getCode()

    if (code) {
      this.loginWithCode(code)
    } else {
      this.redirect()
    }
  }

  private async loginWithCode(code: string) {
    try {
      const response = await axios.post<TTokenResponse>("https://ingrad.sarex.io/api/oauth/token/obtain/", {
        code
      })
      if (response.status === 200) {
        const user = response.data.user
        this.setAccessToken(response.data.access_token)

        this.setUser(new User({
          id: user.id,
          company: user.company,
          email: user.email,
          firstName: user.first_name,
          lastName: user.last_name,
          role: user.role
        }))

        const href = localStorage.getItem(this.localStorageLocationKey);
        if (href) {
          window.location.href = href
        }

        this.setAuthorized(true)
      } else {
        this.status.fail("произошла ошибка")
      }
    } catch (error) {
      this.status.fail("произошла ошибка")
    }
  }

  logout() {
    this.clearUser()
    this.clearAccessToken()
    this.setAuthorized(false)

    this.redirect()
  }

  @action
  private setUser(user: User) {
    this.user = user
    localStorage.setItem("user", JSON.stringify(user.props))
  }

  @action
  private clearUser() {
    this.user = undefined
    localStorage.removeItem("user")
  }

  @action
  private setAccessToken(token: string) {
    this.accessToken = token
    localStorage.setItem("accessToken", token)
  }

  @action
  private clearAccessToken() {
    this.accessToken = undefined
    localStorage.removeItem("accessToken")
  }

  @action
  private setAuthorized(v: boolean) {
    this.authorized = v
  }
}